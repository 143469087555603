<template>
  <div class="page-product-list">
    <!--头部-->
    <PageTop :isLogin="isLogin"></PageTop>
    <!--主体内容-->
    <HeadeSearch ref="handleGetSum"></HeadeSearch>
    <MainTabs
      :isHover="isHover"
      :activeIndex="tabActive"
      @getCurrentData="onClicktab"
    ></MainTabs>
    <!-- 分类筛选 -->
    <div class="search-options-wrap">
      <template>
        <div
          class="search-option"
          v-for="(item, index) in searchOptionsList"
          :key="index"
        >
          <div class="search-left">{{ item.title }}</div>
          <div
            :class="[{ autoCenter: item.isShowMore }, 'searrch-center']"
            :style="{ height: item.isShowMore ? 'auto' : '64px' }"
          >
            <ul class="search-ul">
              <li v-for="(option, index) in item.options" :key="index">
                <b-checkbox
                  type="is-info"
                  v-model="option.isCheck"
                  @input="getSelectItem($event, option)"
                  >{{ option.val }}</b-checkbox
                >
              </li>
            </ul>
          </div>
          <div
            class="search-right"
            @click="showMore(index)"
            v-if="item.options.length > 6"
          >
            <span>更多</span>
            <i
              class="fa fa-caret-right"
              aria-hidden="true"
              :style="{
                transform: item.isShowMore ? 'rotate(-90deg)' : 'rotate(90deg)'
              }"
            ></i>
          </div>
        </div>
      </template>
      <div class="search-total">
        <p v-if="totalNum !== 0">共{{ totalNum }}件相关商品</p>
        <p class="no-result" v-else>
          没有找到相关的商品。
          <!-- 您还可以进行<span>新品登记</span>，登记后普健将为您采购商品！ -->
        </p>
      </div>
    </div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <div class="search-result-wrap">
        <ActiveInfo></ActiveInfo>
        <!-- 综合排序， 销量，价格 -->
        <ul class="result-order-wrap">
          <li
            v-for="(item, index) in orderTypeList"
            :key="index"
            @click="changeOrder(index)"
          >
            <span :class="{ active: index === activeIdx }">{{ item }}</span>
            <div class="angle" v-if="index === 2">
              <div>
                <img
                  src="./images/icon_bgs.png"
                  alt=""
                  v-if="activeIdx === 2 && !ASC"
                />
                <img src="./images/icon_s.png" alt="" v-else />
              </div>
              <div>
                <img
                  src="./images/icon_bgx.png"
                  alt=""
                  v-if="activeIdx === 2 && ASC"
                />
                <img src="./images/icon_x.png" alt="" v-else />
              </div>
              <!-- <i
                class="fa fa-caret-right top"
                aria-hidden="true"
                style="transform:rotate(-90deg)"
                :class="{ active: activeIdx === 2 && !ASC }"
              ></i>
              <i
                class="fa fa-caret-right bottom"
                :class="{ active: activeIdx === 2 && ASC }"
                aria-hidden="true"
                style="transform:rotate(90deg)"
              ></i> -->
            </div>
          </li>
          <li>
            <p class="check">
              <b-checkbox
                type="is-info"
                v-model="isClickActive"
                @input="getSelectAll()"
                >促销活动商品</b-checkbox
              >
            </p>
          </li>
        </ul>
        <!-- 大图展示 -->
        <ProductList
          :productList="productList"
          v-if="!isListMode"
          @handelImg="handelImg"
          @getGoodsList="getIntegralList"
        />
        <!-- 列表展示 -->
        <ListShow
          :productList="productList"
          v-if="isListMode"
          @toAddCart="toAddCart"
        />
        <div class="bottomTitle" v-if="productList.length">
          {{ bottomTitle }}
        </div>
      </div>
    </div>

    <!-- 搜索没有结果图 -->
    <template v-if="totalNum === 0">
      <div class="no-result-wrap">
        <img src="./images/no-result.png" alt />
      </div>
    </template>
    <PageFooter></PageFooter>
  </div>
</template>
<script>
import { MainTabs } from "@components/main-tabs";
import { PageTop } from "@components/page-top";
import { HeadeSearch } from "@components/head-search";
import { searchOptionsList, orderTypeList } from "./search-detail-mock-data.js";
import { ProductList } from "@components/product-list";
import { PageFooter } from "@components/page-footer";
import { Account, AccountStorage } from "@services/account";
import { ListShow } from "@components/list-show";
import { ActiveInfo } from "@components/active-info";
const accountStorage = new AccountStorage();
const account = new Account();
const stationSearchConditions =
  "/pjyy-deepexi-product-center/api/v1/product/PcProduct/stationSearchConditions";
const stationSearch =
  "/pjyy-deepexi-product-center/api/v1/product/PcProduct/stationSearch";
export default {
  data() {
    return {
      busy: true, //上拉的参数
      isHover: true,
      searchOptionsList,
      orderTypeList,
      productList: [], //数据
      checkoutOptionsList: {
        one: [],
        tow: [],
        three: []
      }, // 选中分类的筛选
      isShowMore: false,
      tabActive: -1,
      activeIdx: 0,
      ASC: true,
      isListMode: false,
      saleSort: "", //销量排序
      productSort: "", //价格排序
      page: 1, // 当前页
      totalPageNumber: 1, //总页数
      bottomTitle: "正在加载.....",
      totalNum: 0, //搜索到的商品数量
      searchName: "",
      isClickActive: false, //是否勾选了
      isExternal: false
    };
  },
  mounted() {
    // this.getIntegralList();
    // this.getClassification();
    this.$nextTick(() => {
      this.searchName = this.$route.params.name
    })
    let userInfo = accountStorage.getCacheUserInfo();
    if(userInfo){
      this.isExternal = userInfo.type == 1 ? true : false
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.searchName = to.params.name;
    this.page = 1;
    this.productList = [];
    // this.getIntegralList();
    // this.getClassification();
    next();
  },
  computed: {
    isLogin() {
      //获取登录状态
      return account.isLogined();
    }
  },
  watch: {
    searchName: {
      handler() {
        this.searchName = this.$route.params.name
      },
      deep:true,
      immediate: true
    }
  },
  methods: {
    toAddCart() {
      this.$refs.handleGetSum.getSum();
    },
    handelImg() {
      this.$refs.handleGetSum.getSum();
    },
    // 是否勾选促销活动
    getSelectAll() {
      this.page = 1;
      this.productList = [];
      this.getIntegralList();
    },
    // 上拉加载更多
    loadMore() {
      this.busy = true;
      if (this.page <= this.totalPageNumber) {
        this.bottomTitle = "正在加载...";
        this.getIntegralList(true);
      }
    },
    specialCharacters(string) {
      return string.replace(
        /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
        ""
      );
    },
    // 获取分类筛选条件
    getClassification() {
      let searchName = this.specialCharacters(this.searchName);
      this.$getHttpClient()
        .get(stationSearchConditions + "?name=" + searchName)
        .then(res => {
          // 商品分类
          let product = res.data.data.catalogConditions.map(item => {
            return {
              val: item.value,
              id: item.key + "",
              isCheck: false,
              classify: 1
            };
          });
          // 试剂
          let reagent = res.data.data.formulationConditions.map(item => {
            return {
              val: item.value,
              isCheck: false,
              classify: 2
            };
          });
          // 生产厂家
          // let manufacturer = res.data.data.factoryConditions.filter(item => {
          //   return item != null;
          // });
          // let manufacturer1 = manufacturer.map(item => {
          //   return {
          //     val: item.value,
          //     id: item.key,
          //     isCheck: false,
          //     classify: 3
          //   };
          // });
          this.searchOptionsList[0].options = product || [];
          this.searchOptionsList[1].options = reagent || [];
          // this.searchOptionsList[2].options = manufacturer1 || [];
        });
    },
    // 获取数据
    getIntegralList(flag = false) {
      let userInfo = accountStorage.getCacheUserInfo()
      let data = {
        page: this.page,
        pageSize: 20,
        productName: this.searchName, //搜索的value
        // sort: this.productSort, //价格排序  原来的传参方式
        sort: "ASC", //价格排序
        productCatalog: this.checkoutOptionsList.one.map(item => item.id),
        formulation: this.checkoutOptionsList.tow.map(item => item.val),
        // produceFactory: this.checkoutOptionsList.three.map(item => item.id)
        activityFlag: this.isClickActive ? 1 : 0
      };
      this.$getHttpClient()
        .post(stationSearch, data)
        .then(res => {
          this.page++;
          if(res.data.data.list.length<=0 || !userInfo){
            if (flag) {
              this.productList = this.productList.concat(
                res.data.data.list || []
              );
            } else {
              this.productList = res.data.data.list || [];
            }
            this.totalPageNumber = Math.ceil(res.data.data.total / 20);
            this.totalNum = res.data.data.total || 0;
            setTimeout(() => {
              this.busy = false;
            }, 1000);
            if (this.page > this.totalPageNumber) {
              this.bottomTitle = "数据已加载完";
              this.busy = true;
            }
          }else{
            let arr = []
            res.data.data.list.forEach(item=>{
              arr.push(item.productCode)
            })
            let params = {
              customer_code: userInfo.account,
              product_code_list: arr,
              currentPage: 1,
              pageSize: 20
            }
            if (flag) {
                this.productList = this.productList.concat(
                  res.data.data.list || []
                );
              } else {
                this.productList = res.data.data.list || [];
              }
              this.totalPageNumber = Math.ceil(res.data.data.total / 20);
              this.totalNum = res.data.data.total || 0;
              setTimeout(() => {
                this.busy = false;
              }, 1000);
              if (this.page > this.totalPageNumber) {
                this.bottomTitle = "数据已加载完";
                this.busy = true;
              }
              // 隐藏销售数量 2021-06-09 update by lxr
            // this.$getHttpClient().post('/data-center/adm_oms_product_count',params).then(result=>{
            //   if(result.data.code==200){
            //     res.data.data.list.forEach(item=>{
            //       result.data.data.forEach(it=>{
            //         if(item.productCode==it.product_code){
            //           item.product_buy_num = it.product_buy_num
            //           item.product_buy_num_total = it.product_buy_num_total
            //         }
            //       })
            //     })
            //   }

            // })
          }
        });
    },
    // tab切换
    onClicktab(event) {
      // window.sessionStorage.setItem("cindex",0);
//  if (window.sessionStorage.getItem("cindex")) {
//       } else {
//         window.sessionStorage.setItem("cindex", 0);
//       }
       event.children.map(item=>{
          item.pagenum =0
          item.datalist =[]
        })
       if (this.isExternal) {
        this.$buefy.toast.open({
          message: "您尚未成为正式会员，无法进入该页面",
          type: "is-danger"
        });
        this.$router.push({
          path: "/"
        });
      } else {
        this.$router.push({
          // path: "/channel/channel-detail/" + event.index + "/" +Number(window.sessionStorage.getItem("cindex"))
          path: "/channel/channel-detail/" + event.index + "/0"
        });
      }
    },
    // 点击综合排序、销量、价格排序
    changeOrder(idx) {
      this.activeIdx = idx;
      // if (idx === 0) {
      //   this.ASC = true;
      //   // 综合排名
      //   this.page = 1;
      //   this.productList = [];
      //   this.productSort = "defaultAsc";
      //   this.getIntegralList();
      // }
      // if (idx === 1) {
      //   this.ASC = true;
      //   //按销量搜索
      //   this.page = 1;
      //   this.productList = [];
      //   this.saleSort = 1;
      //   this.productSort = "salesAsc";
      //   this.getIntegralList();
      // }
      // if (idx === 2) {
      //   // 按价格搜索
      //   this.saleSort = "";
      //   if (this.ASC) {
      //     // 升序
      //     this.page = 1;
      //     this.productList = [];
      //     this.productSort = "priceAsc";
      //     this.getIntegralList();
      //   } else {
      //     // 降序
      //     this.page = 1;
      //     this.productList = [];
      //     this.productSort = "priceDesc";
      //     this.getIntegralList();
      //   }
      //   this.ASC = !this.ASC;
      // }
      if (idx === 0) {
        this.ASC = true;
        this.isListMode = !this.isListMode;
        if (this.isListMode) {
          this.orderTypeList[idx] = "大图模式";
        } else {
          this.orderTypeList[idx] = "列表模式";
        }
      }
    },
    // 多选框勾选事件
    getSelectItem($event, item) {
      if (item.classify === 1) {
        if ($event) {
          this.checkoutOptionsList.one.push(item);
        } else {
          let index = this.checkoutOptionsList.one.indexOf(item.id);
          this.checkoutOptionsList.one.splice(index, 1);
        }
      }
      if (item.classify === 2) {
        if ($event) {
          this.checkoutOptionsList.tow.push(item);
        } else {
          let index = this.checkoutOptionsList.tow.indexOf(item.val);
          this.checkoutOptionsList.tow.splice(index, 1);
        }
      }
      // if (item.classify === 3) {
      //   if ($event) {
      //     this.checkoutOptionsList.three.push(item);
      //   } else {
      //     let index = this.checkoutOptionsList.three.indexOf(item.id);
      //     this.checkoutOptionsList.three.splice(index, 1);
      //   }
      // }
      this.page = 1;
      this.getIntegralList();
    },
    // 上边的分类点击拉出更多
    showMore(index) {
      this.searchOptionsList[index].isShowMore = !this.searchOptionsList[index]
        .isShowMore;
      this.$set(this.searchOptionsList, index, this.searchOptionsList[index]);
    }
  },
  beforeDestroy() {
    this.searchName = ''
  },
  components: {
    PageTop,
    HeadeSearch,
    MainTabs,
    ProductList,
    PageFooter,
    ListShow,
    ActiveInfo
  }
};
</script>
<style src="./search-detail.less" lang="less"></style>
