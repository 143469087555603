export var orderTypeList = ["列表模式"];
export var searchOptionsList = [{
  title: "商品分类",
  options: [],
  isShowMore: false
}, {
  title: "剂型",
  options: [],
  isShowMore: false
}
// {
//   title: "生产厂家",
//   options: [],
//   isShowMore: false
// }
];